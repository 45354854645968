import Axios from 'axios';
import {convertApiError} from '../utils/Utils';

let api = Axios;
const init = (axiosApi) => {
  api = axiosApi;
};

const getVehicles = async () => {
  try {
    const response = await api.get('/vehicles');
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const getVehicleById = async (id) => {
  try {
    const response = await api.get(`/vehicles/${id}`);
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const storeVehicle = async (data) => {
  try {
    const response = await api.put('/vehicles', data);
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const getVehicleMakes = async (search) => {
  try {
    const response = await api.get( `/vehicles/makes/${search}`);
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const getVehicleModelsByMake = async (make) => {
  try {
    const response = await api.get(`/vehicle-models-by-make/${make}`);
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const getVehicleYearsByModel = async (modelId) => {
  try {
    const response = await api.get(`/vehicle-years-by-model/${modelId}`);
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
}

const getVehicleModel = async (modelId, year) => {
  try {
    const response = await api.get(`/vehicle-models/${modelId}?year=${year}`);
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
}

const getVehicleLabel = (vehicle) => {
  if (!vehicle.title) {
    return '';
  }
  return vehicle.is_approved ? vehicle.title : vehicle.title + ' (pending approval)';
}

const VehicleService = {
  init,
  getVehicles,
  getVehicleById,
  storeVehicle,
  getVehicleMakes,
  getVehicleModelsByMake,
  getVehicleYearsByModel,
  getVehicleModel,
  getVehicleLabel,
};

export default VehicleService;
