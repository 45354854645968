import Axios from 'axios';

let api = Axios;

const init = (axiosApi) => {
  api = axiosApi;
};

const verifyOtp = async (otp) => {
  try {
    const response = await api.put(
      '/verify-otp',
      { otp },
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const OtpService = {
  init,
  verifyOtp,
};

export default OtpService;
