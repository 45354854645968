function processConfig() {
  // Configure the audience here. By default, it will take whatever is in the config
  // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
  // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
  // don't have an API).
  // If this resolves to `null`, the API page changes to show some helpful info about what to do
  // with the audience.

  const audience =
    process.env.REACT_APP_AUTH0_AUDIENCE && process.env.REACT_APP_AUTH0_AUDIENCE !== 'YOUR_API_IDENTIFIER'
      ? process.env.REACT_APP_AUTH0_AUDIENCE
      : null;

  return {
    version: process.env.REACT_APP_VERSION || '',
    // Test mode: allow tokenless retrieval as if driver was at the location
    testMode: process.env.REACT_APP_TEST_MODE && (process.env.REACT_APP_TEST_MODE.toLowerCase() === 'true'),
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    apiOrigin: process.env.REACT_APP_API_ORIGIN,
    stripePublishKey: process.env.REACT_APP_STRIPE_PUBLISH_KEY,
    authorizeNetHost: process.env.REACT_APP_AUTHORIZE_HOST,
    mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN,
    supportNumber: process.env.REACT_APP_SUPPORT_NUMBER,
    custServiceNumber: process.env.REACT_APP_CUST_SERVICE_NUMBER || process.env.REACT_APP_SUPPORT_NUMBER,
    audience,
    enableVehiclePhotos: process.env.REACT_APP_ENABLE_VEHICLE_PHOTOS && (process.env.REACT_APP_ENABLE_VEHICLE_PHOTOS.toLowerCase() === 'true'),
  };
}
const theConfig = processConfig();
console.info('config', JSON.stringify(theConfig));

export function getConfig() {
   return theConfig;
}
