import { useEffect, useRef } from "react";

export function convertNullsToEmptyStrings(obj) {
  for (let key in obj) {
    if (obj[key] == null) {
      obj[key] = '';
    }
  }
}

export function convertApiError(error) {
  console.log('API error', error);
  let errMsg = error?.response?.data?.error;
  if (errMsg && typeof errMsg === 'string') {
    if (errMsg.startsWith('Error: ')) {
      errMsg = errMsg.substring(7);
    }
    return errMsg;
  } else {
    errMsg = error?.response?.data?.message;
    if (errMsg && typeof errMsg === 'string') {
      if (errMsg.startsWith('Error: ')) {
        errMsg = errMsg.substring(7);
      }
      return errMsg;
    }
  }
  return error;
}

export const useRunOnce = ({ fn, sessionKey }) => {
  const triggered = useRef(false);

  useEffect(() => {
    const hasBeenTriggered = sessionKey
      ? sessionStorage.getItem(sessionKey)
      : triggered.current;

    if (!hasBeenTriggered) {
      fn();
      triggered.current = true;

      if (sessionKey) {
        sessionStorage.setItem(sessionKey, "true");
      }
    }
  }, [fn, sessionKey]);

  return null;
};

export function formatUTCDateInLocalTime(utcString, dateFirst = false) {
  if (!utcString) {
    return null;
  }
  const date = new Date(utcString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear().toString().slice(2);
  let period = 'AM';
  let hour12Format = hours;
  if (hours > 12) {
    hour12Format = hours - 12;
    period = 'PM';
  } else if (hours === 0) {
    hour12Format = 12;
  } else if (hours === 12) {
    period = 'PM';
  }
  return dateFirst ? `${month}/${day}/${year}, ${hour12Format}:${minutes.toString().padStart(2, '0')} ${period}`
    : `${hour12Format}:${minutes.toString().padStart(2, '0')} ${period}, ${month}/${day}/${year}`;
}

import { PhoneNumberUtil } from 'google-libphonenumber';
const phoneUtil = PhoneNumberUtil.getInstance();
export const isValidPhoneNumber = (phone) => {
  try {
    return phone && phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

