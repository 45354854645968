import React, { useEffect, useContext, useState, useRef } from 'react';
import { Context } from 'Store';
import styles from 'assets/jss/material-kit-react/views/landingPage.js';
import { Container, Row, Col } from 'reactstrap';
import FormControl from '@mui/material/FormControl';
import makeStyles from '@mui/styles/makeStyles';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
} from '@mui/material';
import useAPI from 'useAPI';
import UserService from '../services/UserService';
import OtpService from '../services/OtpService';
import FormattedInput from 'components/Pattern/FormattedInput';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react18-input-otp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import types from "../Reducer/types"
import mixpanel from "mixpanel-browser"
import DialogContentText from "@mui/material/DialogContentText"
import TNCDialogBox from './Components/TNCDialogBox';
import Loading from '../components/Loading';
import states from './Settings/States';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import isEqual from 'lodash/isEqual';
import { isValidPhoneNumber } from '../utils/Utils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const useStyles = makeStyles(() => ({
  ...styles,
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export const ProfileComponent = () => {
  let navigate = useNavigate();
  const api = useAPI();
  const classes = useStyles();

  const { state, dispatch } = useContext(Context);
  const { user } = state;

  const [saving, setSaving] = useState(false);
  const [showPhoneInstructions, setShowPhoneInstructions] = useState(null);
  const [openOtp, setOpenOtp] = useState(false);
  const [otp, setOtp] = useState('');
  const [errors, setErrors] = useState({});
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [snackbarError, setSnackbarError] = useState(false);

  const [profile, setProfile] = useState({});
  const [phoneVerified, setPhoneVerified] = useState(null);
  const isPhoneValid = isValidPhoneNumber(profile.phone);
  const initialProfile = useRef(null);
  const phoneInstructionsDisplayed = useRef(false);

  useEffect(() => {
    UserService.init(api);
    OtpService.init(api);
    mixpanel.track('PROFILE');
  }, [api]);

  useEffect(() => {
    if (user) {
      updateProfileFromServer(user);
    }
  }, [user]);

  const refreshUser = async () => {
    const sysUser = await UserService.getUser();
    setProfile({ ...sysUser });
    dispatch({
      type: types.SET_USER,
      payload: sysUser,
    });
  };

  const updateProfileFromServer = (user) => {
    setProfile({...user});
    initialProfile.current = {...user};
  }

  useEffect(() => {
    if (!phoneInstructionsDisplayed.current && !!profile.is_agree && (profile.phone === '' || !profile.phone_verified)) {
      setShowPhoneInstructions(true);
      phoneInstructionsDisplayed.current = true;
    } else {
      setShowPhoneInstructions(false);
    }
    setPhoneVerified(initialProfile.current?.phone_verified && profile.phone === initialProfile.current?.phone);
  }, [profile]);

  const navigateToNextPage = () => {
    setTimeout(() => {
      if (!user.notification_status) {
        navigate('/notifications-settings')
      } else {
        navigate('/');
      }
    }, 1000);
  }

  const handleCloseReviewPhoneDialog = () => {
    setShowPhoneInstructions(false);
  }

  const handleOtpChange = (otp) => {
    setOtp(otp);
  };

  const handleCloseOtpDialog = () => {
    mixpanel.track('CANCEL_OTP');
    setOtp('');
    setOpenOtp(false);
  };

  const verifyOtp = () => {
    mixpanel.track('VERIFY_OTP');
    OtpService.verifyOtp(otp).then((res) => {
      setOtp('');
      setOpenOtp(false);
      if (res === true) {
        refreshUser();
        navigateToNextPage();
      } else {
        setSnackbarMessage('Invalid code. Please re-save to try again.')
        setSnackbarError(true);
      }
    });
  };

  const handlePhoneChange = (phone) => {
    setProfile({
      ...profile,
      phone,
    });
  }

  const handleProfileChange = (prop) => (event) => {
    const newValue = event.target.value;
    setProfile({
      ...profile,
      [prop]: newValue,
    });
  };

  const enableSaveButton = () => {
    return !saving && isPhoneValid && profile.name !== ''
      && (!isEqual(profile, initialProfile.current) || !profile.phone_verified);
  }

  const handleSave = async () => {
    mixpanel.track('SAVE_PROFILE');
    setErrors({});
    if (profile.name === '') {
      setErrors({ ...errors, name: 'Required' });
    }
    if (profile.phone === '') {
      setErrors({ ...errors, phone: 'Required' });
    }

    if (Object.keys(errors).length > 0) {
      console.log(errors);
      return;
    }

    setSaving(true);
    try {
      const updatedUser = await UserService.updateUser(profile);
      dispatch({
        type: types.SET_USER,
        payload: updatedUser,
      });
      updateProfileFromServer(updatedUser);
      setSaving(false);

      if (!updatedUser.phoneIsValid) {
        setSnackbarMessage('Invalid phone number');
        setSnackbarError(true);
      } else {
        setSnackbarMessage('Profile updated');
      }
      if (updatedUser.otpSent) {
        setOpenOtp(true);
      } else if (updatedUser.phoneIsValid) {
        navigateToNextPage();
      }
    } catch (e) {
      console.error(e);
      if (
        e.response &&
        e.response.data &&
        e.response.data.error &&
        e.response.data.error.includes('is not a valid phone number')
      ) {
        setErrors({ ...errors, phone: 'Invalid Phone Number' });
      }
      setSaving(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarMessage(null);
    setSnackbarError(false);
  };

  if (!user && !profile.id) {
    return <Loading/>
  }

  if (!profile.id) {
    return (
      <AuthenticatedContainer>
        <Container className='m-5'>
          <Row className='align-items-center profile-header mb-5 text-center text-md-left'>
            <Col md={2}>
              <img
                src={user.picture}
                alt='Profile'
                className='rounded-circle img-fluid profile-picture mb-3 mb-md-0'
              />
            </Col>
            <Col>
              <p className='lead text-muted'>{user.email}</p>
            </Col>
          </Row>
        </Container>
      </AuthenticatedContainer>
    );
  }

  return (
    <AuthenticatedContainer>
      <TNCDialogBox/>
      <Container className='m-5'>
        <Row>
          <Dialog
            open={!!showPhoneInstructions}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {profile.phone === '' ?
                  'Please enter your phone number, and then click Save to receive a verification text.' :
                  'Please review your phone number for accuracy, and then click Save to receive a verification text.'}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseReviewPhoneDialog}
                color='primary'
                autoFocus
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            open={openOtp}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseOtpDialog}
            aria-labelledby='modal-slide-title'
            aria-describedby='modal-slide-description'
          >
            <DialogTitle
              id='classic-modal-slide-title'
              className={classes.modalHeader}
              component='div'
            >
              <h4>Enter OTP</h4>
              <h5>Please Verify Your Phone Number {profile['phone']}</h5>
            </DialogTitle>
            <DialogContent id='modal-slide-description' sx={{ justifyContent: 'center' }}>
              <OtpInput
                value={otp}
                onChange={handleOtpChange}
                numInputs={4}
                separator={<span>-</span>}
                inputStyle={{
                  width: '30px',
                  height: '30px',
                  margin: '15px',
                  fontSize: '15px',
                  borderRadius: 4,
                  border: '1px solid rgba(0,0,0,0.3)',
                }}
                isInputNum={true}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseOtpDialog}>Cancel</Button>
              <Button onClick={verifyOtp} color='primary'>
                Verify
              </Button>
            </DialogActions>
          </Dialog>
        </Row>
        <Row className='align-items-center profile-header mb-5 text-center text-md-left'>
          <Col md={2}>
            <img
              src={profile.picture}
              alt='Profile'
              className='rounded-circle img-fluid profile-picture mb-3 mb-md-0'
            />
          </Col>
          <Col>
            <p className='lead text-muted'>{profile.email}</p>
          </Col>
        </Row>
        <Row>
          <Box pb={5}>
            <TextField
              autoFocus
              margin='dense'
              id='name'
              label='Name*'
              value={profile.name}
              onChange={handleProfileChange('name')}
              type='text'
              fullWidth
              error={!!errors.name}
            />
            <TextField
              margin='dense'
              id='address'
              label='Address'
              value={profile.address}
              onChange={handleProfileChange('address')}
              type='text'
              fullWidth
              multiline
              rows={2}
            />

            <TextField
              margin='dense'
              id='city'
              label='City'
              value={profile.city}
              onChange={handleProfileChange('city')}
              type='text'
              fullWidth
            />

            <FormControl fullWidth margin='dense' className={classes.formControl}>
              <InputLabel id='state-id'>State</InputLabel>
              <Select label='State' value={profile.state || ''} required onChange={handleProfileChange('state')}>
                <MenuItem
                  disabled
                  value=''
                >
                  Choose State
                </MenuItem>

                {states &&
                  states.map((state) => (
                    <MenuItem
                      value={`${state}`}
                      key={`${state}`}
                    >
                      {`${state}`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormattedInput
              name='zipcode'
              format='#####'
              mask=' '
              label='Zip Code'
              value={profile.zipcode}
              onChange={handleProfileChange('zipcode')}
            />

            <div style={{position: 'relative', marginTop: '8px'}}>
              <PhoneInput
                required={true}
                placeholder='Phone Number'
                preferredCountries={['us','ca','gb','mx']}
                style={{
                  width: '100%',
                  paddingRight: '40px', // Add padding for the adornment space
                }}
                inputStyle={{
                  width: '100%',
                  fontSize: '16px',
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                }}
                defaultCountry="us"
                value={profile.phone || ''}
                onChange={(phone) => handlePhoneChange(phone)}
              />
              {phoneVerified ? (
                <CheckCircleIcon
                  style={{
                    color: '#006600',
                    position: 'absolute',
                    right: '8px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                />
              ) : (
                <Tooltip title="Unverified Phone Number" aria-label="add">
                  <IconButton
                    size="large"
                    style={{
                      color: 'red',
                      position: 'absolute',
                      right: 0,
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  >
                    <ErrorOutlinedIcon/>
                  </IconButton>
                </Tooltip>
              )}
            </div>
            {!isPhoneValid && <div style={{ color: 'red' }}>Please enter a valid phone number</div>}

            <div className={classes.wrapper}>
              <Button
                onClick={handleSave}
                variant='contained'
                disabled={!enableSaveButton()}
                color='primary'
                fullWidth
                className='mt-3'
              >
                Save
              </Button>
              {saving && <CircularProgress size={24} className={classes.buttonProgress}/>}
            </div>
            <div>
              <Snackbar
                open={!!snackbarMessage}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
              >
                <Alert severity={snackbarError ? 'error' : 'success'} onClose={handleCloseSnackbar}>
                  {snackbarMessage}
                </Alert>
              </Snackbar>
            </div>
          </Box>
        </Row>
      </Container>
    </AuthenticatedContainer>
  );
};

export default ProfileComponent;
