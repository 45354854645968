import Axios from 'axios';
const Utils = require('../utils/Utils');

let api = Axios;
const init = (axiosApi) => {
  api = axiosApi;
};

const getVehiclePhotos = async (vehicleId) => {
  try {
    const response = await api.get(`/photos/vehicles/${vehicleId}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

const PhotoService = {
  init,
  getVehiclePhotos,
};

export default PhotoService;
