import Axios from 'axios';
import {convertApiError} from "../utils/Utils"

let api = Axios;
const init = (axiosApi) => {
  api = axiosApi;
};

const listAllUserSessions = async () => {
  const response = await api.get('/user-sessions-list');
  return response.data;
};

const isQueuedToPark = (session) => {
  return session && (session.status === 'CUST_QUEUED_TO_PARK' || session.status === 'PARKING_WAITING_FOR_GATE_TO_OPEN');
}

const isReadyToEnter = (session) => {
  return session && session.status === 'PARKING_READY_TO_ENTER';
}

const isFailedToEnter = (session) => {
  return session && session.status === 'PARKING_FAILED_TO_ENTER';
}

const isOnPallet = (session) => {
  return session && session.status === 'PARKING_ON_PALLET';
}

const isReadyToStow = (session) => {
  return session && session.status === 'READY_TO_STOW';
}

const isStowing = (session) => {
  return session &&
    (session.status === 'STOWING' ||
        session.status === 'CLOSING_GATE_PRIOR_TO_STOWING' ||
        session.status === 'GATE_CLOSED_READY_TO_STOW' ||
        session.status === 'STOWING_COMPLETE');
}

/**
 * Show "In valet queue" message until car is stowing
 */
const isInValetParkingQueue = (session) => {
  return session?.valet_id && (
    session.status === 'VALET_QUEUED_TO_PARK' ||
    session.status ==='CUST_QUEUED_TO_PARK' ||
    session.status ==='PARKING_WAITING_FOR_GATE_TO_OPEN' ||
    session.status ==='PARKING_READY_TO_ENTER' ||
    session.status ==='PARKING_ON_PALLET' ||
    session.status ==='READY_TO_STOW' ||
    session.status === 'STOWING' ||
    session.status === 'STOWING_COMPLETE'
  );
}

const isAwaitingValetRetrieval = (session) => {
  return session?.valet_id &&
    (session.status === 'VALET_QUEUED_TO_RETRIEVE'
      || session.post_payment_status === 'VALET_QUEUED_TO_RETRIEVE'
      || session.status === 'CUST_QUEUED_TO_RETRIEVE'
      || session.status === 'RETRIEVING_WAITING_FOR_CAR'
      || session.status === 'RETRIEVING_READY_TO_LEAVE'
    );
}

const isParked = (session) => {
  return session && session.status === 'PARKED'
    && !session.post_payment_status
    && !session.isPostPayment;
}

const isQueuedToRetrieve = (session) => {
  return session && session.status === 'CUST_QUEUED_TO_RETRIEVE';
}

const isRetrievingWaitingForCar = (session) => {
  return session && session.status === 'RETRIEVING_WAITING_FOR_CAR';
}

/**
 * Covers both retrieval and canceling pre-stowing while on pallet
 */
const isRetrievingReadyToLeave = (session) => {
  return session && (session.status === 'RETRIEVING_READY_TO_LEAVE' || session.status === 'CANCELED_WAITING_TO_LEAVE');
}

const isQueuedToRepark = (session) => {
  return session && session.status === 'CUST_QUEUED_TO_REPARK';
}

const isReparkingWaitingForCar = (session) => {
  return session && session.status === 'REPARKING_WAITING_FOR_CAR';
}

const isReparkingCarAvailable = (session) => {
  return session && session.status === 'REPARKING_CAR_AVAILABLE';
}

const isWaitingForPaymentToProcess = (session) => {
  return session && session.status === 'PARKED' && (!!session.post_payment_status || session.isPostPayment);
}

/**
 * When the session is transitioning we need to poll for updates
 */
const isTransitionalState = (session) => {
  if (!session) {
    return false;
  }
  switch (session.status) {
    case 'CUST_QUEUED_TO_PARK':
    case 'CUST_QUEUED_TO_RETRIEVE':
    case 'CUST_QUEUED_TO_REPARK':
    case 'PARKING_WAITING_FOR_GATE_TO_OPEN':
    case 'PARKING_READY_TO_ENTER':
    case 'READY_TO_STOW':
    case 'CLOSING_GATE_PRIOR_TO_STOWING':
    case 'GATE_CLOSED_READY_TO_STOW':
    case 'STOWING':
    case 'STOWING_COMPLETE':
    case 'RETRIEVING_WAITING_FOR_CAR':
    case 'RETRIEVING_READY_TO_LEAVE':
    case 'CANCELED_WAITING_TO_LEAVE':
    case 'REPARKING_WAITING_FOR_CAR':
    case 'VALET_QUEUED_TO_PARK':
    case 'VALET_QUEUED_TO_RETRIEVE':
      return true;
    case 'PARKING_ON_PALLET':
      return !!session.valet_id; // When valet parking, keep polling through stowing
    default:
      return false;
  }
}

/**
 * When to show the carousel progress indicator
 */
const isCarouselInUse = (session) => {
  if (!session) {
    return false;
  }
  switch (session.status) {
    case 'READY_TO_STOW':
    case 'CLOSING_GATE_PRIOR_TO_STOWING':
    case 'GATE_CLOSED_READY_TO_STOW':
    case 'STOWING':
    case 'RETRIEVING_WAITING_FOR_CAR':
    case 'REPARKING_WAITING_FOR_CAR':
      return true;
    default:
      return false;
  }
}

const isShowParkingStatus = (session) => {
  return isParked(session) ||
    isQueuedToRetrieve(session) ||
    isRetrievingWaitingForCar(session) ||
    isQueuedToRepark(session) ||
    isReparkingWaitingForCar(session) ||
    isReparkingCarAvailable(session) ||
    (isReadyToStow(session) && !!session.start_time) ||
    (isStowing(session) && !!session.start_time);
}

const isShowParkButton = (session) => {
  return !isShowParkingStatus(session);
}

/**
 * Used when scanning a static QR code at a carousel
 * @param token the QR code token that was scanned
 * @param locationId the current location (for validation)
 * @param vehicleId the vehicle that is being parked
 */
const getCarouselSlot = async (token, locationId, vehicleId) => {
  try {
    const response = await api.post(`/park/arrive`, {
      token,
      locationId,
      vehicleId,
    });
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const geoPark = async (carouselId, vehicleId, latitude, longitude) => {
  try {
    const response = await api.post(`/park/geopark`, {
      carouselId,
      vehicleId,
      latitude,
      longitude
    });
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
}

/**
 * Get a new parking token for carousel park/repark/retrieve
 * @param vehicle required
 * @param action either PARK, STOW, REPARK, or RETRIEVE
 * @param sessionI only for stowing or retrieving, with active session involved
 * @param carousel if the vehicle can only park at a specific carousel (i.e. assigned or only 1 available or only 1 fits the vehicle)
 */
const getParkingToken = async ({vehicle, location, action, session, carousel}) => {
  try {
    const locationId = location ? location.id : session ? session.location_id : carousel.location_id;
    const vehicleId = vehicle ? vehicle.id : session.vehicle_id;
    const response = await api.post('/park/token', {
      vehicleId,
      locationId,
      action,
      ...(session && {
        sessionId: session.id,
      }),
      ...(carousel && {
        carouselId: carousel.id,
      }),
    });
    return response.data;
  } catch (error) {
    if (error.response?.status === 409) {
      // Conflict, session id is invalid
      throw error;
    }
    throw convertApiError(error);
  }
}

/**
 * Get a new parking token for valet parking (VALET_PARK action)
 * @param vehicleId required
 * @param locationId required
 */
const getParkingTokenForValetParking = async (vehicleId, locationId) => {
  try {
    const response = await api.post('/park/token', {
      vehicleId,
      locationId,
      action: 'VALET_PARK',
    });
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
}

const getParkingTokenStatus = async (token) => {
  try {
    const response = await api.post('/park/token/status', {
      token,
    });
    if (response.status === 202) {
      return null;
    }
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
}

/**
 * This API call is equivalent to getParkingTokenStatus() for retrieval in a non-carousel, valet-operated parking lot.
 * The response can be either a payment summary for approval ({ totalAmount, discountAmount, chargedAmount }) or a session/payment response
 */
const tokenlessRetrieve = async (paymentSummary) => {
  try {
    const response = await api.post(`/tokenless-retrieve`, {
      ...(!!paymentSummary && {paymentSummary}),
    });
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
}

const cancelSessionPayment = async () => {
  const response = await api.post('/cancel-session-payment');
  return response.data;
}

const stowVehicleForParking = async (token, sessionId) => {
  try {
    const response = await api.post(`/park/stow`, {
      token,
      sessionId,
    });
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
}

const retrieveVehicleForRepark = async (token, vehicleId) => {
  try {
    const response = await api.post(`/repark/retrieve`, {
      token,
      vehicleId,
    });
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
}

const stowVehicleForReparking = async (token, vehicleId) => {
  try {
    const response = await api.post(`/repark/restow`, {
      token,
      vehicleId,
    });
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
}

const applyDiscountCode = async (code, sessionId) => {
  try {
    const response = await api.post('apply-discount-code', {
      code,
      sessionId
    });
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
}

const isDiscountApplied = (session) => {
  return !!session?.discount?.code;
}

const getDiscountDescription = (session) => {
  if (!session?.discount?.type) {
    return '';
  }
  const discount = session.discount;
  switch (discount.type) {
    case 'Percentage':
      return `${discount.value}% discount applied`;
    case 'Amount':
      return `$${discount.value.toFixed(2)} discount applied`;
    case 'Hours':
      return `${discount.value} hour discount applied`;
    default:
      console.error('Unknown discount type', discount.type);
      return `Discount code ${discount.code} applied`
  }
}

const getCurrentSession = async (waitingForPaymentToProcess) => {
  try {
    const session = await api.get(`current-session${waitingForPaymentToProcess ? '?waitingForPaymentToProcess=true' : ''}`);
    return session.data;
  } catch (error) {
    throw convertApiError(error);
  }
}

const retrieve = async (token, sessionId, paymentSummary) => {
  try {
    const response = await api.post(`/retrieve`, {
      ...(!!paymentSummary && {paymentSummary}),
      token,
      sessionId,
    });
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
}

const leaveDuringRepark = async (session) => {
  try {
    const response = await api.post('/leaveduringrepark', {
      sessionId: session.id,
    });
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
}

const cancelSession = async (sessionId) => {
  try {
    const session = await api.post(`/cancel-session/${sessionId}`);
    return session.data;
  } catch (error) {
    throw convertApiError(error);
  }
}

const getParkingOptions = async (location) => {
  try {
    const options = await api.get(`/parking-app/parking-opts/${location.id}`);
    return options.data;
  } catch (error) {
    throw convertApiError(error);
  }
}

const getSessionAfterPayment = async (paymentId, redirectStatus) => {
  try {
    const session = await api.get(`/session-post-payment?paymentId=${paymentId}&status=${redirectStatus}`);
    return session.data;
  } catch (error) {
    throw convertApiError(error);
  }
}

const ParkingSessionService = {
  init,
  listAllUserSessions,
  isQueuedToPark,
  isReadyToEnter,
  isFailedToEnter,
  isOnPallet,
  isReadyToStow,
  isStowing,
  isParked,
  getCarouselSlot,
  getParkingToken,
  getParkingTokenForValetParking,
  getParkingTokenStatus,
  stowVehicleForParking,
  retrieve,
  isInValetParkingQueue,
  tokenlessRetrieve,
  leaveDuringRepark,
  isQueuedToRetrieve,
  isRetrievingWaitingForCar,
  isRetrievingReadyToLeave,
  retrieveVehicleForRepark,
  isQueuedToRepark,
  isReparkingWaitingForCar,
  isReparkingCarAvailable,
  stowVehicleForReparking,
  isAwaitingValetRetrieval,
  isTransitionalState,
  isCarouselInUse,
  applyDiscountCode,
  isDiscountApplied,
  getDiscountDescription,
  getCurrentSession,
  cancelSession,
  geoPark,
  isShowParkButton,
  isShowParkingStatus,
  getParkingOptions,
  isWaitingForPaymentToProcess,
  getSessionAfterPayment,
  cancelSessionPayment,
};

export default ParkingSessionService;
